import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import {adminUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {decisionToolsData, communicationToolsData, exerciseToolsData} from 'data/tools-data';
import {getCRMASheets, getCRMHSheets, getSTSheets} from 'helpers/admin-helper';
import {getText} from 'helpers/language-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import './download-company-games.scss';

const DownloadCompanyGames = ({company, facilitators, games, groups, archivedGames}) => {
	const languageId = 'en'; // TODO? Or is it okay it is just always in english?

	/* Prepare sheets for each game type */
	let CRMASheets = getCRMASheets(languageId);
	let CRMHSheets = getCRMHSheets();
	let STSheets = getSTSheets();

	/* Filter (only include games of this company) and sort */
	let allGames = (games.concat(archivedGames)).filter((game) => {
		return game.companyId === company.id;
	});
	allGames = sortArrayByProperty(allGames, 'playDate', 'DESC');

	/* Loop over all games */
	allGames.forEach((game) => {
		/* Get corresponding sheets */
		let sheets = [];
		if (game.type === 'crm-aeroplanes') sheets = [...CRMASheets];
		if (game.type === 'crm-helicopters') sheets = [...CRMHSheets];
		if (game.type === 'safetytraining') sheets = [...STSheets];
		if (sheets.length === 0) return;

		/* Check if game is an archived game */
		let isArchivedGame = (game.hasOwnProperty('groups') && game.hasOwnProperty('deleted'));

		/* Get game data */
		const gameData = gamesData[game.type];
		const scenarioData = gameData.scenarios.find((sc) => {return sc.id === game.scenario;});

		/* Get facilitator data */
		const facilitatorData = facilitators.find((f) => {return f.id === game.facilitatorId;});

		/* Get game phase */
		const gamePhaseData = gameData.gamePhases.find((g) => {return g.id === game.gamePhase;});

		/* Count groups and players */
		let gameGroups = (isArchivedGame 
			? game.groups
			: groups.filter((g) => {return g.gameId === game.id;})
		);
		let gamePlayers = 0;
		gameGroups.forEach((group) => {
			if (group.players) {
				group.players.forEach(() => {gamePlayers += 1;});
			}
		});

		/* CRMA, CRMH, ST: Game overview data */
		let overviewDataObj = {
			gameId: game.id, 
			playDate: game.playDate,
			facilitator: (facilitatorData ? facilitatorData.email : adminUiTexts.deleted),
			type: getText(gameData.title, languageId) + 
				(gameData.subtitle ? '(' + getText(gameData.subtitle, languageId) + ')' : ''),
			scenario: (scenarioData ? getText(scenarioData.title, languageId) : '-'),
			phase: getText(gamePhaseData.title, languageId),
			groups: gameGroups.length,
			players: gamePlayers,
			archived: (isArchivedGame ? adminUiTexts.yes : adminUiTexts.no)
		};
		let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'overview';});
		if (sheetIndex >= 0) sheets[sheetIndex].data.push(overviewDataObj);
		
		/* Loop over groups */
		gameGroups.forEach((group) => {
			if (!group.isPlaying === true) return;

			/* CRMA, CRMH, ST: Groups data */
			let groupsDataObj = {gameId: game.id, group: group.title, players: 0, playerIds: ''};
			if (group.players && group.players.length > 0) {
				groupsDataObj.players = group.players.length;
				group.players.forEach((player, index) => {
					if (index > 0) {
						groupsDataObj.playerIds += ', ' + player.id;
					} else {
						groupsDataObj.playerIds += player.id;
					}
				});
			}
			let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'groups';});
			if (sheetIndex >= 0) sheets[sheetIndex].data.push(groupsDataObj);

			/* CRMA, CRMH, ST: Selected crew data */
			let selectedCrewDataObj = {gameId: game.id, group: group.title};
			if (group.selectedCrew && group.selectedCrew.length > 0) {
				group.selectedCrew.forEach((slot) => {
					let crewMember = gameData.crewData.availableCrew.find((c) => {return c.id === slot.id;});
					let slotData = gameData.crewData.crewSlots.find((s) => {return s.id === slot.slotId;});
					if (crewMember && slotData) {
						selectedCrewDataObj['crew' + slot.slotId] = 
							getText(crewMember.name, languageId) + ' (' + getText(slotData.title, languageId) + ')';
					}
				});
			}
			sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'crew';});
			if (sheetIndex >= 0) sheets[sheetIndex].data.push(selectedCrewDataObj);

			/* CRMA, CRMH, ST: Reflections */
			gameData.reflectionsData.forEach((reflection) => {
				let selectedOptions = null;

				// Selected focus points
				if (reflection.showFocusPoints && reflection.numberOfFocusPointsToSelect && reflection.focusPoints) {
					selectedOptions = '-';
					if (
						group.focusPointIds && 
						group.focusPointIds.length >= reflection.numberOfFocusPointsToSelect - 1
					) {
						for (let i = 0; i < reflection.numberOfFocusPointsToSelect; i++) {
							const focusPointData = reflection.focusPoints.find((fp) => {
								return fp.id === group.focusPointIds[i];
							});
							if (focusPointData) {
								if (i > 0) selectedOptions += ', ';
								selectedOptions += getText(focusPointData.title, languageId);
							}
						}	
					}
				}

				// Selected topics 
				if (reflection.topics && reflection.topics.length > 0) {
					selectedOptions = '-';
					if (group.selectedTopics && group.selectedTopics.length > 0) {
						group.selectedTopics.forEach((topicId, index) => {
							let topicData = reflection.topics.find((topic) => {return topic.id === topicId;});
							selectedOptions += getText(topicData.title, languageId);
							if (index < (group.selectedTopics.length - 1)) selectedOptions += ', ';
						});
					}
				}
				// Selected DD 
				if (reflection.numberOfDirtyDozensToSelect) {
					selectedOptions = '-';
					if (group.dirtyDozenIds && group.dirtyDozenIds.length > 0) {
						selectedOptions = '';
						group.dirtyDozenIds.forEach((ddId, index) => {
							if (index >= reflection.numberOfDirtyDozensToSelect) return;
							let ddData = dirtyDozenData.find((dd) => {return dd.id === ddId;});
							selectedOptions += getText(ddData.title, languageId);
							if (index < (reflection.numberOfDirtyDozensToSelect - 1)) selectedOptions += ', ';
						});
					}
				}

				// Save selected options on its own row if reflection has intro text 
				if (selectedOptions && selectedOptions.length > 0 && reflection.introText) {
					const reflectionDataObj = {
						gameId: game.id, 
						group: group.title, 
						reflection: getText(reflection.title, languageId),
						questionNumber: 0,
						question: getText(reflection.introText, languageId),
						answer: '-',
						selected: selectedOptions							
					};
					let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'reflections';});
					if (sheetIndex >= 0) sheets[sheetIndex].data.push(reflectionDataObj);			
				}

				// Best practices
				if (reflection.numberOfBestPracticesCardsToSelect && reflection.question) {
					if (group.bestPracticesCards && group.bestPracticesCards.length > 0) {
						const bestPracticesData = gameData.bestPracticesData;
						group.bestPracticesCards.forEach((card, index) => {
							let cardData = bestPracticesData.find((bpd) => {return bpd.id === card.id;});
							if (cardData) {
								let reflectionDataObj = {
									gameId: game.id, 
									group: group.title, 
									reflection: getText(reflection.title, languageId),
									questionNumber: (index + 1),
									question: getText(reflection.question, languageId),
									answer: '-',
									selected: getText(cardData.title, languageId)
								};
								if (group.reflectionAnswers && group.reflectionAnswers.length > 0) {
									let groupAnswerData = group.reflectionAnswers.find((a) => {
										return (a.gameStep === reflection.gameStep && a.id === cardData.id);
									});
									if (groupAnswerData) reflectionDataObj.answer = groupAnswerData.value.toString();
								}	
								let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'reflections';});
								if (sheetIndex >= 0) {
									sheets[sheetIndex].data.push(reflectionDataObj);									
								}
							}
						});
					}
				}

				// Question answers 
				if (reflection.questions) {
					reflection.questions.forEach((question, qIndex) => {
						let reflectionDataObj = {
							gameId: game.id, 
							group: group.title, 
							reflection: getText(reflection.title, languageId),
							questionNumber: (qIndex + 1),
							question: getText(question.text, languageId),
							answer: '-',
							selected: (qIndex === 0 && !reflection.introText && selectedOptions ? selectedOptions : '')
						};
						if (group.reflectionAnswers && group.reflectionAnswers.length > 0) {
							let groupAnswerData = group.reflectionAnswers.find((a) => {
								return (a.gameStep === reflection.gameStep && a.id === question.id);
							});
							if (groupAnswerData) reflectionDataObj.answer = groupAnswerData.value.toString();
						}
						let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'reflections';});
						if (sheetIndex >= 0) sheets[sheetIndex].data.push(reflectionDataObj);			
					});
				}
			});

			/* CRMA */
			if (game.type === 'crm-aeroplanes') {
				// Ground event cards data
				let groundEventCardsCRMDataObj = {gameId: game.id, group: group.title};
				if (scenarioData && scenarioData.eventCards && group.gameStepCards) {
					const groundGameSteps = ['check-in', 'preflight', 'boarding'];
					let eventCards = [...Array(12)].map(() =>  {return null;});
					groundGameSteps.forEach((gameStep, gameStepIndex) => {
						if (group.gameStepCards.hasOwnProperty(gameStep)) {
							group.gameStepCards[gameStep].cards.forEach((card, cardIndex) => {
								if (card) {
									let eventCardIndex = (cardIndex > 3 
										? ((gameStepIndex - 1) * 4 + (cardIndex - 4))
										: gameStepIndex * 4 + cardIndex
									);
									if (!eventCards[eventCardIndex]) {
										let eventCardData = scenarioData.eventCards.find((c) => {
											return c.id === card.id;
										});
										eventCards[eventCardIndex] = {
											id: card.id, 
											title: eventCardData.title 
												? getText(eventCardData.title, languageId) 
												: '',  
											text: eventCardData.text ? getText(eventCardData.text, languageId) : ''
										};
									}
									if (card.selectedOptionId) {
										eventCards[eventCardIndex].selectedOptionId = card.selectedOptionId;
									}
								}
							});
						}
					});
					eventCards.forEach((card, index) => {
						groundEventCardsCRMDataObj['card' + (index + 1) + 'a'] = 
							(card ? card.title : '');
						groundEventCardsCRMDataObj['card' + (index + 1) + 'b'] = 
							(card ? card.selectedOptionId : '');
					});
				}
				let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'ground';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(groundEventCardsCRMDataObj);	

				// Air event cards data
				let airEventCardsCRMDataObj = {gameId: game.id, group: group.title};
				if (scenarioData && scenarioData.eventCards && group.gameStepCards) {
					const airGameSteps = ['flight1', 'flight2', 'flight3']; // TODO: only works for CRM-planes
					let eventCards = [...Array(12)].map(() =>  {return null;});
					airGameSteps.forEach((gameStep, gameStepIndex) => {
						if (group.gameStepCards.hasOwnProperty(gameStep)) {
							group.gameStepCards[gameStep].cards.forEach((card, cardIndex) => {
								if (card) {
									let eventCardIndex = (cardIndex > 3 
										? ((gameStepIndex - 1) * 4 + (cardIndex - 4))
										: gameStepIndex * 4 + cardIndex
									);
									if (!eventCards[eventCardIndex]) {
										let eventCardData = scenarioData.eventCards.find((c) => {
											return c.id === card.id;
										});
										eventCards[eventCardIndex] = {
											id: card.id, 
											title: eventCardData.title ? getText(eventCardData.title, languageId) : '', 
											text: eventCardData.text ? getText(eventCardData.text, languageId) : ''
										};
									}
									if (card.selectedOptionId) {
										eventCards[eventCardIndex].selectedOptionId = card.selectedOptionId;
									}
								}
							});
						}
					});
					eventCards.forEach((card, index) => {
						airEventCardsCRMDataObj['card' + (index + 1) + 'a'] = 
							(card ? card.title : '');
						airEventCardsCRMDataObj['card' + (index + 1) + 'b'] = 
							(card ? card.selectedOptionId : '');
					});
				}
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'air';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(airEventCardsCRMDataObj);
				
				// Decisions 
				let decisionToolData = decisionToolsData.find((d) => {return d.id === game.decisionTool;});
				if (decisionToolData) {
					decisionToolData.questions.forEach((question) => {
						let decitionDataObj = {
							gameId: game.id, 
							group: group.title, 
							type: getText(decisionToolData.title, languageId),
							question: getText(question.title, languageId),
							answer: '-'
						};
						if (group.decisionAnswers && group.decisionAnswers.length > 0) {
							let groupAnswerData = group.decisionAnswers.find((a) => {
								return (a.id === question.id);
							});
							if (groupAnswerData) decitionDataObj.answer = groupAnswerData.value.toString();
						}
						let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'decisions';});
						if (sheetIndex >= 0) sheets[sheetIndex].data.push(decitionDataObj);	
					});
				}

				/* Communication */
				let communicationToolData = communicationToolsData.find((d) => {
					return d.id === game.communicationTool;
				});
				if (communicationToolData) {
					communicationToolData.questions.forEach((question) => {
						let communicationDataObj = {
							gameId: game.id, 
							group: group.title, 
							type: getText(communicationToolData.title, languageId),
							question: getText(question.title, languageId),
							answer: '-'
						};
						if (group.communicationAnswers && group.communicationAnswers.length > 0) {
							let groupAnswerData = group.communicationAnswers.find((a) => {
								return (a.id === question.id);
							});
							if (groupAnswerData) communicationDataObj.answer = groupAnswerData.value.toString();
						}
						let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'communication';});
						if (sheetIndex >= 0) sheets[sheetIndex].data.push(communicationDataObj);	
					});
				}

				/* Results */
				let dirtyDozenTotal = group.dirtyDozenValues.reduce((a, b) => {return a + b;}, 0);
				let total = (dirtyDozenTotal + group.inconvenienceValue + 2 * group.riskValue);
				let resultsCRMDataObj = {
					gameId: game.id, 
					group: group.title, 
					dirtydozen: dirtyDozenTotal,
					inconvenience: group.inconvenienceValue,
					risk: (2 * group.riskValue),
					total: total
				};
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'results';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(resultsCRMDataObj);	

				/* Dirty dozen */
				let dirtyDozensDataObj = {gameId: game.id, group: group.title, total: dirtyDozenTotal};
				group.dirtyDozenValues.forEach((dd, index) => {
					let property = 'dirtydozen-' + (index + 1); 
					dirtyDozensDataObj[property] = dd;
				});
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'dirtydozens';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(dirtyDozensDataObj);	
			}

			/* ST */
			if (game.type === 'safetytraining') {
				// Event cards data
				let eventCardsSTDataObj = {gameId: game.id, group: group.title};
				if (scenarioData && scenarioData.daysData && group.gameStepCards) {
					scenarioData.daysData.forEach((day, dayIndex) => {
						day.cards.forEach((cardData, cardIndex) => {
							let index = (dayIndex) * 4 + cardIndex + 1;
							
							eventCardsSTDataObj['card' + index + 'a'] = getText(cardData.title, languageId);
							eventCardsSTDataObj['card' + index + 'b'] = '-';

							if (group.gameStepCards.hasOwnProperty(day.gameStep)) {
								let gameStepCard = group.gameStepCards[day.gameStep].cards.find((c) => {
									return c.id === cardData.id;
								});
								if (gameStepCard && gameStepCard.selectedOptionId) {
									let crewMemberData = gameData.crewData.availableCrew.find((c) => {
										return c.id === gameStepCard.selectedCrewMemberId;
									});
									eventCardsSTDataObj['card' + index + 'b'] = 
										gameStepCard.selectedOptionId + 
										' (' + getText(crewMemberData.name, languageId) + ')';
								}
							}
						});						
					});
				}
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'eventcards';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(eventCardsSTDataObj);	

				// Results 
				let total = (group.timeValue + group.riskValue + group.costValue);
				let resultsSTDataObj = {
					gameId: game.id, 
					group: group.title, 
					time: group.timeValue,
					risk: group.riskValue,
					cost: group.costValue,
					total: total
				};
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'results';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(resultsSTDataObj);	
			}

			/* CRMH */
			if (game.type === 'crm-helicopters') {
				
				// Event cards
				let eventCardsCRMHDataObj = {gameId: game.id, group: group.title};
				if (scenarioData && scenarioData.eventCards && group.gameStepCards) {
					const gameSteps = ['check-in', 'scramble', 'outbound', 'on-site', 'transport-hospital'];
					let eventCards = [...Array(20)].map(() =>  {return null;});
					gameSteps.forEach((gameStep, gameStepIndex) => {
						if (group.gameStepCards.hasOwnProperty(gameStep, gameStepIndex)) {
							group.gameStepCards[gameStep].cards.forEach((card, cardIndex) => {
								if (card) {
									let eventCardData = scenarioData.eventCards.find((c) => {return c.id === card.id;});
									let eventCardIndex = gameStepIndex * 4 + cardIndex;
									eventCards[eventCardIndex] = {
										id: card.id, 
										title: eventCardData.title ? getText(eventCardData.title, languageId) : '', 
										text: eventCardData.text ? getText(eventCardData.text, languageId) : ''
									};
									if (card.selectedOptionId) {
										eventCards[eventCardIndex].selectedOptionId = card.selectedOptionId;
									}
								}
							});
						}
					});
					eventCards.forEach((card, index) => {
						eventCardsCRMHDataObj['card' + (index + 1) + 'a'] = 
							(card ? card.title : '');
						eventCardsCRMHDataObj['card' + (index + 1) + 'b'] = 
							(card ? card.selectedOptionId : '');
					});
				}
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'eventcards';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(eventCardsCRMHDataObj);

				// Exercise 
				const exerciseToolId = game.exerciseTool ? game.exerciseTool : exerciseToolsData[0].id;
				const exerciseToolData = exerciseToolsData.find((d) => {return d.id === exerciseToolId;});
				if (exerciseToolData) {
					exerciseToolData.questions.forEach((question) => {
						let exerciseDataObj = {
							gameId: game.id, 
							group: group.title, 
							type: getText(exerciseToolData.title, languageId),
							question: getText(question.title, languageId),
							answer: '-'
						};
						if (group.exerciseAnswers && group.exerciseAnswers.length > 0) {
							let groupAnswerData = group.exerciseAnswers.find((a) => {
								return (a.id === question.id);
							});
							if (groupAnswerData) exerciseDataObj.answer = groupAnswerData.value.toString();
						}
						let sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'exercise';});
						if (sheetIndex >= 0) sheets[sheetIndex].data.push(exerciseDataObj);	
					});
				}

				// Results
				const total = group.crewSafetyValue + group.missionSuccessValue + group.complianceValue;
				const resultsCRMHObj = {
					gameId: game.id,
					group: group.title,
					safety: group.crewSafetyValue,
					success: group.missionSuccessValue,
					compliance: group.complianceValue,
					total: total
				};
				sheetIndex = sheets.findIndex((sheet) => {return sheet.id === 'results';});
				if (sheetIndex >= 0) sheets[sheetIndex].data.push(resultsCRMHObj);	
			}
		});

		/* Update corresponding sheets */
		if (game.type === 'crm-aeroplanes') CRMASheets = [...sheets];
		if (game.type === 'crm-helicopters') CRMHSheets = [...sheets];
		if (game.type === 'safetytraining') STSheets = [...sheets];
	});


	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	return (
		<div className="DownloadCompanyGames">
			<div className="DownloadCompanyGames-title">Download Game Data</div>

			<ExcelFile element={<button className="DownloadCompanyGames-btn">CRM - AEROPLANES</button>}>
				{CRMASheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>

			<ExcelFile element={<button className="DownloadCompanyGames-btn">CRM - HELICOPTER</button>}>
				{CRMHSheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>

			<ExcelFile element={<button className="DownloadCompanyGames-btn">
				HUMAN FACTORS TRAINING for MAINTENANCE
			</button>}>
				{STSheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>
		</div>
	);
};

DownloadCompanyGames.propTypes = {
	company: PropTypes.object.isRequired,
	facilitators: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	archivedGames: PropTypes.array.isRequired,
};

export default DownloadCompanyGames;