import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { gamesData} from 'data/games-data';
import { generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import GameBoardStat from 'components/game-board-stat/game-board-stat';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import BestPracticeCardFront from 'components/best-practice-card/best-practice-card-front';
import './best-practices.scss';

const BestPractices = (props) => {
	let {
		game,
		group,
		handleGoToPage,
		confirmAndContinue, 	
		toggleInfoPopup,
		toggleSelection,
		confirmBestPracticesCards,
		selectedCount,
		handleInputText
	} = props;

	/* Confirm button status */
	let confirmButtonClasses = ['next'];
	if (game.isPaused) confirmButtonClasses = ['paused'];
	if (game.type === 'safetytraining') confirmButtonClasses = confirmButtonClasses.concat(['st']);

	/* Game step data */
	const gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === 'best-practices';});

	/* Reflection data */
	let bestPracticesData = gamesData[game.type].bestPracticesData;
	const [reflectionData] = useState(
		gamesData[game.type].reflectionsData.find((reflection) => {return reflection.gameStep === group.gameStep;})
	);
	
	/* Confirmation button status */
	let confirmBtnIsDisabled = false;
	if (bestPracticesData && group.hasOwnProperty('reflectionAnswers') && group.bestPracticesCardsConfirmed) {
		bestPracticesData.forEach((item) => {
			let isSelected = false;
			if (group.bestPracticesCards) {
				group.bestPracticesCards.forEach((groupCard) => {
					if ( groupCard.id === item.id ) {
						isSelected = true;
					}
				});
			} 
			if (isSelected) {
				if (
					!group.reflectionAnswers.some((answer) => {
						return (
							answer.gameStep === group.gameStep &&
							answer.id === item.id &&
							answer.value &&
							answer.value.length > 0
						);
					})
				) { confirmBtnIsDisabled = true; }
			}
		});
	}

	/* Load and store answer values locally */
	const [answers, setAnswers] = useState([]);
	useEffect(() => {
		if (bestPracticesData && group.bestPracticesCardsConfirmed) {
			let initAnswersArray = [];
			bestPracticesData.forEach((item) => {
				/* Answer template  */
				let initAnswer = {
					gameStep: group.gameStep,
					id: item.id,
					value: ''
				};

				/* Group answer */		
				const groupAnswer = group.reflectionAnswers.find((a) => {
					return (a.gameStep === group.gameStep && a.id === item.id);
				});
				if (groupAnswer && groupAnswer.value !== '') { initAnswer.value = groupAnswer.value;}

				initAnswersArray.push(initAnswer);
			});
			setAnswers(initAnswersArray);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [group.gameStep, group.bestPracticesCardsConfirmed]);

	/* Update locally stored answers when props are updated */
	useEffect(()=>{
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			bestPracticesData.forEach((item) => {
				const newAnswerIndex = newAnswers.findIndex((a) => {return a.id === item.id;});
				const groupAnswer = group.reflectionAnswers.find((a) => {
					return (a.gameStep === group.gameStep && a.id === item.id);
				});		
				if (newAnswerIndex >= 0 && groupAnswer) {
					if (newAnswers[newAnswerIndex].value !== groupAnswer.value) {
						newAnswers[newAnswerIndex].value = groupAnswer.value;
					}
				}
			});
			return newAnswers;
		});
	}, [group.gameStep, group.reflectionAnswers, bestPracticesData]);

	/* Update locally stored answers & database when user is typing */
	const handleTextChange = (e, id, gameStep) => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			answers.forEach((answer, i) => {
				if (answer.id === id && answer.gameStep === gameStep) { newAnswers[i].value = e.target.value; }
			});
			return newAnswers;
		});
		handleInputText(e);
	};	
	
	return (
		<div className="BestPractices">
			<div className="BestPractices-header">
				<ProgressBar 
					gameType={game.type}
					page="game-board"
					title={getText(gameStepData.title, game.languageId)}
					onClick={() => {handleGoToPage('welcome');}}
				/>
				{/* Extra time */}
				<GameBoardStat
					languageId={game.languageId}
					animate={false}
					type="time"
					title={getText(generalUiTexts.extraTime, game.languageId)}
					gameType={game.type}
					value={(group.timeValue ? group.timeValue : 0)}
					maxValue = {appConfig['st']['timeMax']}
				/>

				{/* Extra risk */}
				<GameBoardStat
					languageId={game.languageId}
					animate={false}
					type="risk"
					title={getText(generalUiTexts.extraRisk, game.languageId)}
					gameType={game.type}
					value={(group.riskValue ? group.riskValue : 0)}
					maxValue = {appConfig['st']['riskMax']}
				/>

				{/* Extra cost */}
				<GameBoardStat
					languageId={game.languageId}
					animate={false}
					type="cost"
					title={getText(generalUiTexts.extraCost, game.languageId)}
					gameType={game.type}
					value={(group.costValue ? group.costValue : 0)}
					maxValue = {appConfig['st']['costMax']}
				/>
				<Button
					text={''}
					classes={['st', 'info']}
					onClick={() => {toggleInfoPopup();}}
				/>
				{ group.bestPracticesCardsConfirmed ?
					<Button
						text={game.isPaused 
							? getText(generalUiTexts.paused, game.languageId) 
							: getText(generalUiTexts.next, game.languageId) 
						}
						classes={confirmButtonClasses}
						isDisabled={ game.isPaused ? true : 
							confirmBtnIsDisabled ? true : false
						}
						onClick={() => { confirmAndContinue('best-practices'); }}
					/>
					:
					<Button
						text={game.isPaused 
							? getText(generalUiTexts.paused, game.languageId) 
							: getText(generalUiTexts.next, game.languageId) 
						}
						classes={confirmButtonClasses}
						isDisabled={ game.isPaused ? true : (selectedCount <= 1) ? true : false }
						onClick={() => { confirmBestPracticesCards(); }}
					/>
				}
			</div>
			{/* Best Practices */}
			<div className="BestPractices-content">
				<h2 className="BestPractices-title">{`${getText(reflectionData.title, game.languageId)} // 
					${ group.bestPracticesCardsConfirmed 
			? getText(reflectionData.subtitle2, game.languageId) 
			: getText(reflectionData.subtitle, game.languageId)}`}</h2>
				<div className={`BestPractices-board 
					${group.bestPracticesCardsConfirmed ? 'reflection' : ''}`}>
					{group.bestPracticesCardsConfirmed  && <p className="BestPractices-reflectiondescription">
						{getText(reflectionData.question, game.languageId)}
					</p>}
					{bestPracticesData.map((card) => {
						let isSelected = false;
						if (group.bestPracticesCards) {
							group.bestPracticesCards.forEach((groupCard) => {
								if ( groupCard.id === card.id ) {
									isSelected = true;
								}
							});
						} 
						return (
							<div key={card.id} className={`BestPractices-cardWrap 
								${isSelected ? 'selected' : 'deselected'}`
							}>
								<BestPracticeCardFront
									languageId={game.languageId}
									cardData={card}
									isDisabled={selectedCount <= 1 ? false : !isSelected}
									isSelected={isSelected}
									toggleSelection={toggleSelection}
									bestPracticesCardsConfirmed={(group.bestPracticesCardsConfirmed 
										? group.bestPracticesCardsConfirmed : false)}
								/>					
							</div>
						);
					})}
					{group.bestPracticesCardsConfirmed && bestPracticesData.map((card) => {
						let answer = null;
						let reflectionAnswer = answers.find((answer) => {
							return answer.gameStep === group.gameStep && answer.id === card.id;
						});
						if (reflectionAnswer) answer = reflectionAnswer.value;
						let isSelected = false;
						if (group.bestPracticesCards) {
							group.bestPracticesCards.forEach((groupCard) => {
								if ( groupCard.id === card.id ) {
									isSelected = true;
								}
							});
						} 
						if (isSelected) {
							return (
								<div className="Reflection-answer" key={card.id}>	
									<textarea									
										id={card.id}
										className="Reflection-textarea"
										name={card.id}
										placeholder={getText(
											generalUiTexts.reflection.textPlaceholder, 
											game.languageId
										)}
										rows="5"
										value={answer ? answer : ''}
										onChange={(event) => {handleTextChange(event, card.id, group.gameStep);}}
									></textarea>
								</div>
							);
						}
						return null;
					})}
				</div>
			</div>
		</div>
	);
};

BestPractices.propTypes = {
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	toggleSelection: PropTypes.func.isRequired,
	confirmBestPracticesCards: PropTypes.func.isRequired,
	selectedCount: PropTypes.number.isRequired,
	handleInputText: PropTypes.func.isRequired,
};

export default BestPractices;